/**
 * Font Awesome
 * @link https://discourse.roots.io/t/how-to-use-font-awesome-5-in-your-sage-theme/11737
 */

import { library, dom } from '@fortawesome/fontawesome-svg-core';

// Regular Icons
import {
    faCalendar,
    faCalendarAlt,
    faHeart,
    faMap
} from '@fortawesome/free-regular-svg-icons';

// Solid Icons
import {
    faAddressCard,
    faAngleDown,
    faArrowsLeftRight,
    faArrowsSplitUpAndLeft,
    faBook,
    faBuildingColumns,
    faChevronDown,
    faChevronUp,
    faCircleInfo,
    faCircleQuestion,
    faCircleUser,
    faClock,
    faComment,
    faCommentDots,
    faComments,
    faCompress,
    faDatabase,
    faEnvelope,
    faExpand,
    faFile,
    faFileExport,
    faGift,
    faHouseChimney,
    faLink,
    faLocationDot,
    faMagnifyingGlass,
    faMagnifyingGlassMinus,
    faMagnifyingGlassPlus,
    faMobileAlt,
    faPhone,
    faPrint,
    faRss,
    faTriangleExclamation,
    faUser,
    faUsers,
    faXmark
} from '@fortawesome/free-solid-svg-icons';

// Brand Icons
import {
    faFacebook,
    faFacebookF,
    faSquareFacebook,
    faInstagram,
    faSquareInstagram,
    faLinkedinIn,
    faOrcid,
    faXTwitter,
    faSquareXTwitter,
    faYoutube,
    faSquareYoutube
} from "@fortawesome/free-brands-svg-icons";

export default () => {
    // Add regular icons to the library
    library.add(
        faCalendar,
        faCalendarAlt,
        faHeart,
        faMap
    );

    // Add solid icons to the library
    library.add(
        faAddressCard,
        faAngleDown,
        faArrowsLeftRight,
        faArrowsSplitUpAndLeft,
        faBook,
        faBuildingColumns,
        faChevronDown,
        faChevronUp,
        faCircleInfo,
        faCircleQuestion,
        faCircleUser,
        faClock,
        faComment,
        faCommentDots,
        faComments,
        faCompress,
        faDatabase,
        faEnvelope,
        faExpand,
        faFile,
        faFileExport,
        faGift,
        faHouseChimney,
        faLink,
        faLocationDot,
        faMagnifyingGlass,
        faMagnifyingGlassMinus,
        faMagnifyingGlassPlus,
        faMobileAlt,
        faPhone,
        faPrint,
        faRss,
        faTriangleExclamation,
        faUser,
        faUsers,
        faXmark
    );

    // Add brand icons to the library
    library.add(
        faFacebook,
        faFacebookF,
        faSquareFacebook,
        faInstagram,
        faSquareInstagram,
        faLinkedinIn,
        faOrcid,
        faXTwitter,
        faSquareXTwitter,
        faYoutube,
        faSquareYoutube
    );

    // Tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
    dom.watch();
}
